<template>
  <div class="d-flex flex-column fill-height">

    <AppBar />

    <v-card
      class="align-self-center ma-1 ma-sm-3 px-2 py-1 overflow-auto print-no-shadow print-no-border"
      max-width="800"
    >
      <v-card-title class="justify-center">
        Herzlich Willkommen im SchILDweb Klassenbuchmodul!
      </v-card-title>
      <v-card-text class="text-justify">
        <template v-if="isAdmin">
          <p>
            Damit Sie das Klassenbuchmodul mit Ihren Daten füllen und administrieren können,
            müssen Sie nun eine Verbindung zwischen Ihrer SchILD-NRW-Installation und dem Klassenbuchmodul
            auf dem Webserver herstellen.
            Hierfür benötigen Sie ein kleines zusätzlichen Programm mit dem Namen <strong>SchILDplan</strong>.
            Laden Sie dieses an Ihrem SchILD-PC herunter und gewährleisten Sie,
            dass es Zugriff auf Ihre SchILD-Datenbank hat.
          </p>
          <p>
            Gehen Sie hierzu wie folgt vor:
          </p>
          <ul>
            <li>
              Laden Sie sich die <strong>SchILDplan</strong> Zip-Datei über den
              <a href="https://download.ribeka.com/Software/SchILD/SchILDplan2/SchILDplan.zip">
                Downloadlink
              </a>
              herunter
            </li>
            <li>
              Erstellen Sie auf Ihrem SchILD-PC ein neues Verzeichnis mit dem Namen
              <strong>SchILDplan</strong> z.B.: <code>C:\SchILDplan</code>
            </li>
            <li>Entpacken Sie die <code>SchILDplan.zip</code> in Ihrem neuen <strong>SchILDplan</strong>-Verzeichnis</li>
            <li>Klicken Sie mit Doppelklick auf das Setup <code>SchILDplan.exe</code></li>
            <li><strong>SchILDplan</strong> öffnet sich und ist automatisch mit Ihrer SchILD-Datenbank verbunden</li>
          </ul>
          <br>
          <p>
            Laden Sie sich hier das
            <a href="https://download.ribeka.com/Documents/SchILDplan/SchILDplan-Benutzerhandbuch.pdf" target="_blank">
              SchILDplan Handbuch
            </a>
            herunter.
          </p>
          <p>
            In diesem sind alle notwendigen ersten Schritte für die Inbetriebnahme und Datenübertragung detailliert beschrieben.
            Bitte beachten Sie, dass Ihre SchILD-Datenbank zum Zeitpunkt der Ersteinrichtung nicht in Benutzung sein sollte.
            Schließen Sie daher bitte vor der Nutzung von <strong>SchILDplan</strong> alle anderen SchILD-Produkte (z.B. SchILD-NRW).
          </p>
          <p>
            Nach erfolgreicher Einrichtung ist das Klassenbuchmodul bereit für den Einsatz an Ihrer Schule.
          </p>
        </template>
        <p>
          Alle für die tägliche Nutzung benötigten Informationen finden Sie im
          <a href="https://download.ribeka.com/Documents/SchILDweb_Klassenbuchmodul/SchILDweb-Klassenbuch-DIN-A4.pdf" target="_blank">
            Klassenbuchmodul Handbuch
          </a>
          .
        </p>
      </v-card-text>
    </v-card>

  </div>
</template>

<script lang="js">
import AppBar from '@/components/app-bar'

export default {
  name: 'InfoPage',
  components: {
    AppBar
  },
  computed: {
    isAdmin() {
      return !!this.$store.state.user?.hasRole('ROLE_ADMIN')
    }
  }
}
</script>
